import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EditComponent } from 'src/app/shared/components/edit/edit.component';
import { colorConfig } from 'src/environments/color-configs';
import { MatSort, Sort } from '@angular/material/sort';
import { AuthService } from 'src/utils/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-release-alerts',
  templateUrl: './release-alerts.component.html',
  styleUrls: ['./release-alerts.component.scss']
})
export class ReleaseAlertsComponent implements OnInit {
  showTable: boolean = false;
  noRecords: boolean = false;
  releaseAlertArray = [];
  displayCols = ['AlertMsg','TimeCreated','actions'];
  orgId = '';
  searchUrl = '';
  sortUrl = '';
  description = '';
  searchName = '';
  // currentRow = 10;
  totalRecords = 0;
  length = this.totalRecords;
  rowEven = 'row-orange';
  pageSize = 10;
  pageEvent: PageEvent;
  defaultRows: any = this.pageSize;
  defaultPage: any = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100, 200];
  thBg = '#e9ba9e';
  domain = 'snowpaths';
  sensors=[];
  searchBg = 'rgb(221, 92, 15)';
  buttonTextColor = 'black';
  userPoolConfigs: any = [];

  constructor(private apiService: ApiService, private helper: HelperService,
    private authService: AuthService, private dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    document.addEventListener('visibilitychange', this.handleTabChange);
    this.domain = this.apiService.getLocalStorage('domain');
      this.thBg = colorConfig[this.domain]['thBackground'];
      this.rowEven = colorConfig[this.domain]['reports']['tableRowEvenClass'];
      this.searchBg = colorConfig[this.domain]['reports']['searchBg'];
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.apiService.setAuthResult(session);
        let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = tokenValue['custom:organizationid'];
      }
    });
    this.sortUrl='sortType=asc&sortBy=Name';
    this.getReleaseAlerts();
  }
  ngOnDestroy() {
    document.removeEventListener('visibilitychange', this.handleTabChange);
  }
  handleTabChange = () => {
    if (document.hidden) {
      console.log('Tab is inactive');
      // Modify header & footer styles here
    } else {
      // Reset header & footer styles
      this.domain = this.apiService.getLocalStorage('domain');
      this.thBg = colorConfig[this.domain]['thBackground'];
      this.rowEven = colorConfig[this.domain]['reports']['tableRowEvenClass'];
      this.searchBg = colorConfig[this.domain]['reports']['searchBg'];
    }
  };
  searchChange(event) {
    console.log(event)
    if (event !== '') {
      this.searchUrl = '&name=' + event;
    } else {
      this.searchUrl = '';
    }
    this.defaultPage = 0;
    this.getReleaseAlerts();
  }
  async getReleaseAlerts() {
    var url = 'alerts?type=NewRelease';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.releaseAlertArray = data['NewRelease'];
      this.totalRecords = data['NewRelease'].length;
      if (this.totalRecords > 0) {
        this.showTable = true;
        this.noRecords = false;
      } else {
        this.noRecords = true;
      }
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  handlePage(event: PageEvent) {
    console.log(event);
    this.defaultPage = event.pageIndex;
    this.defaultRows = event.pageSize;
    this.getReleaseAlerts();
  }
  addRelease(alertData, getType: any) {
    var title = getType === 'post' ? 'Add alert' : 'Update alert';
    var userPoolData  = [];
    var pooldata = JSON.parse(atob(this.apiService.getLocalStorage('poolData')));
    for(let pool in pooldata){
      userPoolData.push(pooldata[pool])
    }
    const modalData = {};
    modalData['editData'] = {};
    modalData['userPoolData'] = userPoolData;
    modalData['editData'].OrgId = 0;
    if (getType === 'patch') {
      modalData['editData'] = alertData;
    }
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        message: ' ',
        data: modalData,
        orgId: this.orgId,
        type: getType === 'post' ? 'releasePost' : 'releasePatch'
      },
      minWidth: 60,
      // minHeight: 400
    });
    confirmDialog.afterClosed().subscribe(result => {
        console.log(result);
        this.reload();
    });
  }
  async deleteData(releaseObj) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this alert?: \n\n ' + releaseObj['AlertMsg']
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.delDoc(releaseObj)
      }
    });
  }
  async delDoc(releaseObj) {
    let url = 'alerts?inactiveAlert=true';
    var payload = {
      "alertIds":[releaseObj.Id]
  }
    await this.apiService.updatedata(url, payload).subscribe(
      (res) => {
        this.helper.openSnackBar("Alert deleted successfully!", "OK")
        this.reload();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  reload(){
    window.location.reload();
    // this.router.navigate(['/Admin/Release-alerts']);
  }
}

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { HelperService } from 'src/app/api/helper.service';
import { AuthService } from 'src/utils/auth.service';
import { colorConfig } from 'src/environments/color-configs';

@Component({
  selector: 'app-logos',
  templateUrl: './logos.component.html',
  styleUrls: ['./logos.component.scss']
})
export class LogosComponent implements OnInit {
  public files: any[] = [];
  saveBg = 'rgb(241, 119, 46)';
  domain = 'snowpaths';
  showWarning: boolean = false;
  base64textString: any;
  picture: string
  isImageSaved = false;
  cardImageBase64: any = '';
  authResult:any;
  orgId='';
  filetype='';
  tokenValue: any = {};
  isMaster: boolean = false

  constructor(private helper: HelperService, private apiservice: ApiService,
    private authService : AuthService,) { }

  ngOnInit() {
    document.addEventListener('visibilitychange', this.handleTabChange);
    this.domain = this.apiservice.getLocalStorage('domain');
      this.saveBg = colorConfig[this.domain]['logo']['saveBackground'];
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiservice.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiservice.getLocalStorage('role');
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiservice.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiservice.managementToken = currentOrgToken[1];
          this.apiservice.useManagementToken = true;
        }else {
          this.apiservice.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = this.tokenValue['custom:organizationid'];
      }
    });
  }
  ngOnDestroy() {
    document.removeEventListener('visibilitychange', this.handleTabChange);
  }
  handleTabChange = () => {
    if (document.hidden) {
      console.log('Tab is inactive');
      // Modify header & footer styles here
    } else {
      // Reset header & footer styles
      this.domain = this.apiservice.getLocalStorage('domain');
      this.saveBg = colorConfig[this.domain]['logo']['saveBackground'];
    }
  };
  async save() {
    var url = 'logo?folder=Logos&fileName=Logo'+this.orgId+'.'+this.filetype;
    var base64 = this.cardImageBase64.split(',').pop();
    var Payload = {
      "imageBase64": base64
    };
    await this.apiservice.postNewdata(url,Payload).subscribe(
      (res: any) => {
        console.log(res);
        this.helper.openSnackBar("Logo uploaded Successfully!", "OK")
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )

  }
  onFileChange(evt) {
    const file = evt.target.files[0];
    if (!file) {
      return false;
    }
    const reader = new FileReader();

    reader.onload = () => {
      this.picture = reader.result as string;
    };

    console.log(btoa(this.picture));
    this.helper.openSnackBar("Successfully selected logo!", 'Close');
  }
  // handleReaderLoaded(e) {
  //   console.log(btoa(e))
  // }
  fileChangeEvent(fileInput: any) {
    this.showWarning = false;
    this.isImageSaved = false;
    this.cardImageBase64 = '';
    // this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 500000;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;
      this.filetype = fileInput.target.files[0]['name'].split('.').pop()

      if (fileInput.target.files[0].size > max_size) {
        // this.imageError =
        // 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
        this.showWarning = true;
        return false;
      }

      // if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
      //     // this.imageError = 'Only Images are allowed ( JPG | PNG )';
      //     return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          console.log(img_height, img_width);


          if (img_height > max_height && img_width > max_width) {
            // this.imageError =
            //     'Maximum dimentions allowed ' +
            //     max_height +
            //     '*' +
            //     max_width +
            //     'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

}

import { Component, OnInit } from '@angular/core';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { MatCheckboxChange, MatTableDataSource } from '@angular/material';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/api/api.service';
import { AuthService } from 'src/utils/auth.service';
import { HelperService } from 'src/app/api/helper.service';
import { Router } from '@angular/router';
import { colorConfig } from 'src/environments/color-configs';

@Component({
  selector: 'app-portals',
  templateUrl: './portals.component.html',
  styleUrls: ['./portals.component.scss']
})
export class PortalsComponent implements OnInit {
  showTable = false;
  noRecords = false;
  urlOrigin='';
  thBg = '#e9ba9e';
  rowEven = 'row-orange';
  domain = 'snowpaths';
  orgId = '';
  portalsArray:any
  totalRecords = 0;
  length = this.totalRecords;
  pageSize = 10;
  pageEvent: PageEvent;
  defaultRows: any = this.pageSize;
  defaultPage: any = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100, 200];
  searchUrl='';
  sortUrl = '';
  displayCols = ['Name', 'portal', 'TruckHide','ShowPin'];
  searchDescription = ''
  tokenValue: any = {};
  isMaster: boolean = false;

  constructor(private authService: AuthService,private apiService: ApiService,
    private helper: HelperService, private router: Router) { }

  ngOnInit() {
    document.addEventListener('visibilitychange', this.handleTabChange);
    this.domain = this.apiService.getLocalStorage('domain');
      this.thBg = colorConfig[this.domain]['thBackground'];
      this.rowEven = colorConfig[this.domain]['reports']['tableRowEvenClass'];
    this.urlOrigin = window.location.origin;
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = this.tokenValue['custom:organizationid'];
      }
    });
    this.sortUrl='sortBy=Name&sortType=asc';
    this.getPortals();
  }
  ngOnDestroy() {
    document.removeEventListener('visibilitychange', this.handleTabChange);
  }
  handleTabChange = () => {
    if (document.hidden) {
      console.log('Tab is inactive');
      // Modify header & footer styles here
    } else {
      // Reset header & footer styles
      this.domain = this.apiService.getLocalStorage('domain');
      this.urlOrigin = window.location.origin;
      this.thBg = colorConfig[this.domain]['thBackground'];
      this.rowEven = colorConfig[this.domain]['reports']['tableRowEvenClass'];
    }
  };
  async getPortals(){
    var url = 'portal?orgId=' + this.orgId;
    this.apiService.getSearchwithPagination(url, this.searchUrl, this.sortUrl, this.defaultPage, this.defaultRows).subscribe((data: any) => {
      this.portalsArray = data['portals']['portals'];
      this.totalRecords = data['portals']['count'];
      if (this.totalRecords > 0) {
        this.showTable = true;
      } 
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  searchChange(event) {
    console.log(event)
    if (event !== '') {
      this.searchUrl = '&name=' + event;
    } else {
      this.searchUrl = '';
    }
    this.defaultPage = 0;
    this.getPortals();
  }
  sortData(sort: Sort) {
    console.log(sort);
    this.searchUrl = '';
    if (sort.direction) {
      var key = sort.active;
      if(key !== 'portal'){
        this.sortUrl = "sortBy=" + key + "&sortType=" + sort.direction;
        this.getPortals();
      }
    } else {
      this.sortUrl = '';
      this.getPortals();
    }
  }
  handlePage(event: PageEvent) {
    console.log(event);
    this.defaultPage = event.pageIndex;
    this.defaultRows = event.pageSize;
    this.getPortals();
  }
  getTableData(val, col, rowdata){
    if(col === 'portal'){
      var midPath = rowdata.IsTransportHub?"/transport/":"/public/";
      var text = this.urlOrigin+midPath+val;
      return text;
    }else {
      return val;
    }

  }
  getPath(rowdata,getVal){
    var midPath = rowdata.IsTransportHub?"transport/":"public/";
    var path = midPath+getVal;
    window.open(path, '_blank');
    // this.router.navigate([path]);
  }
  changeHeader(col){
    var header = col === 'StartMonth'?"Start Month":col === 'EndMonth'?"End Month":col === 'TruckHide'?"Vehicle Hide":col === 'ShowPin'?'Show pin':col;
    return header;
  }
  updatePortal(id, obj) {
    var url = 'portal?portalId=' + id;
    this.apiService.updatedata(url, obj).subscribe((data: any) => {
      console.log(data)
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  activeChange(truckObj, ob: MatCheckboxChange) {
    console.log(ob.checked);
    var payload = {};
    payload['TruckHide'] = ob.checked;
    this.updatePortal(truckObj['Id'], payload)
  }
  pinStateChange(truckObj, ob: MatCheckboxChange) {
    console.log(ob.checked);
    var payload = {};
    payload['ShowPin'] = ob.checked;
    this.updatePortal(truckObj['Id'], payload)
  }

}

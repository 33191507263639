import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { AuthService } from 'src/utils/auth.service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { HelperService } from 'src/app/api/helper.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { colorConfig } from 'src/environments/color-configs';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  allPortals = [];
  alertMessages: any;
  MobileAlertText:string ='';
  saveBg = 'rgb(241, 119, 46)';
  delBg = 'white';
  delColor = 'black';
  domain = 'snowpaths';
  alertObj = {};
  authResult: any;
  alertMessage = '';
  orgId = '';
  showPage = false;
  selectedPortal: any;
  username = '';
  tokenValue: any = {};
  isMaster: boolean = false

  constructor(private authService: AuthService, private apiService: ApiService,
    private helper: HelperService, private dialog: MatDialog) { }

  ngOnInit() {
    document.addEventListener('visibilitychange', this.handleTabChange);
    this.domain = this.apiService.getLocalStorage('domain');
    // if(this.domain === 'streetpaths' || this.domain === 'fleetpaths'){
      this.saveBg = colorConfig[this.domain]['alertMessage']['saveBackground'];
      this.delBg = colorConfig[this.domain]['alertMessage']['deleteBackground'];
      this.delColor = colorConfig[this.domain]['alertMessage']['deleteColor'];
    // }
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = this.tokenValue['custom:organizationid'];
      }
    });
    this.getPortals();
    this.username = this.apiService.getLocalStorage('username');
  }
  ngOnDestroy() {
    document.removeEventListener('visibilitychange', this.handleTabChange);
  }
  handleTabChange = () => {
    if (document.hidden) {
      console.log('Tab is inactive');
      // Modify header & footer styles here
    } else {
      console.log('Tab is active '+this.selectedPortal);
      // Reset header & footer styles
      this.domain = this.apiService.getLocalStorage('domain');
      this.saveBg = colorConfig[this.domain]['alertMessage']['saveBackground'];
      this.delBg = colorConfig[this.domain]['alertMessage']['deleteBackground'];
      this.delColor = colorConfig[this.domain]['alertMessage']['deleteColor'];
    }
  };
  async getPortals() {
    var url = 'portal?orgId=' + this.orgId;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
        this.selectedPortal = this.allPortals[0]['Id'];
        this.getAlertMessage(this.selectedPortal);
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getAlertMessage(portalId) {
    
    var url = 'alertMessage?orgId=' + this.orgId + '&portalId=' + portalId + '&isCurrent=true';
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res);
        this.alertMessages = res['alertMessages'];
        if (this.alertMessages && (this.alertMessages['Text'] || this.alertMessages['MobileAlertText'])) {
          this.alertMessage = this.alertMessages['Text'];
          this.MobileAlertText = this.alertMessages['MobileAlertText'];
        }else {
          this.alertMessage = '';
          this.MobileAlertText = '';
        }
        this.showPage = true;
        if (this.alertMessages) { }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getportalId(portalId) {
    console.log(portalId);
    this.selectedPortal = portalId;
    this.getAlertMessage(portalId);
  }
  async saveAlert() {
    var url = '';
    var Payload = {};
    this.showPage = false;
    if (this.alertMessages && this.alertMessages['Id']) {
      url = 'alertMessage?alertId='+this.alertMessages['Id'];
      Payload['Text']=this.alertMessage;
      Payload['MobileAlertText'] = this.MobileAlertText;
      await this.apiService.updatedata(url, Payload).subscribe(
        (res: any) => {
          console.log(res);
          this.alertMessages=res;
          this.getAlertMessage(this.selectedPortal);
        }, (error) => {
          console.log(error);
          this.helper.openSnackBar(error, "OK")
        }
      )
    } else {
      if(this.MobileAlertText === ''){
        this.MobileAlertText=this.alertMessage
      }
      url = 'alertMessage';
      Payload = {
        "IsCurrent": true,
        "CreatedByUserId": this.username,
        "Text": this.alertMessage,
        "MobileAlertText": this.MobileAlertText,
        "OrganizationId": this.orgId,
        "PortalId": this.selectedPortal
      }
      await this.apiService.postNewdata(url, Payload).subscribe(
        (res: any) => {
          console.log(res);
          this.getAlertMessage(this.selectedPortal);
        }, (error) => {
          console.log(error);
          this.helper.openSnackBar(error, "OK")
        }
      )
    }
  }
  async deleteData() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Please confirm that you\'d like to delete this alert message:?: \n\n ' + this.alertMessages['Text']
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.delAlert()
      }
    });
  }
  async delAlert() {
    var url = 'alertMessage?alertId=' + this.alertMessages['Id'];
    await this.apiService.deleteData(url).subscribe(
      (res: any) => {
        console.log(res);
        this.getAlertMessage(this.selectedPortal);
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
}

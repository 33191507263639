import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { AuthService } from 'src/utils/auth.service';

@Component({
  selector: 'app-management-landing-page',
  templateUrl: './management-landing-page.component.html',
  styleUrls: ['./management-landing-page.component.scss']
})
export class ManagementLandingPageComponent implements OnInit {
  userpoolData: any = [];
  authResult: any;
  filteredData: any = [];
  orgTokensOnStorage: any = {};
  isMaster: boolean = null;
  searchDescription: any = '';
  tokenValue: any = {};
  orgId: any = '';
  isPageLoaded: boolean = false;

  constructor(private helper: HelperService, private apiService: ApiService, private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = this.tokenValue['custom:organizationid'];
        this.apiService.setAuthResult(session);
        this.getOrgDetails();
      }
    });
    if (!this.authResult) {
      // alert("Hi")
      localStorage.clear();
      this.router.navigate[('/')]
    }

    // this.getOrgLoginToken();
  }
  
  async getOrgDetails() {
    var url = 'organization';
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        this.userpoolData = res.filter((org: any) => {
          return org.Active
        });
        if (this.apiService.getLocalStorage('role') === 'Master') {
          this.isMaster = true;
        }
        this.userpoolData.sort((a, b) => a.orgId - b.orgId);
        this.filteredData = this.userpoolData;
        this.isPageLoaded = true;
        // this.getOrgLoginToken(this.orgId, null)
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader()
      }
    )
  }
  isAuthenticated() {
    let res = this.helper.isAuthenticated();
    return this.helper.isAuthenticated();
    // return true;
  }
  async getOrgLoginToken(getorgId, getUrl) {
    this.helper.openSnackBar("Authenticating...", "OK")
    var url = 'userLogin';
    var orgdata = {
      "masterUsername": this.apiService.getLocalStorage('username'),
      "password": "SNOW$02660$ma$",
      "orgId": Number(getorgId)
    }
    var payload = {
      'input': btoa(JSON.stringify(orgdata))
    }
    var existingOrgIds = this.apiService.getLocalStorage('masterTokens');
    this.orgTokensOnStorage = existingOrgIds ? JSON.parse(existingOrgIds) : {};
    await this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        var org = {}
        this.orgTokensOnStorage[getorgId] = res.token;
        this.apiService.addLocalStorage('masterTokens', JSON.stringify(this.orgTokensOnStorage))
        // this.router.navigate([getUrl], { queryParams: { orgId: getorgId }});
        if(getUrl){
        const urlOrigin = window.location.origin;
          var path = urlOrigin + getUrl + '?manageOrg=' + getorgId;
          window.open(path, '_blank');
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  goto(orgId, getUrl) {
    this.router.navigate([getUrl],
      { queryParams: { 'manageOrg': orgId } });
  }
  filterTable(): void {
    this.filteredData = this.userpoolData.filter(item => {
      const matchesName = this.searchDescription
        ? (item.Name.toLowerCase().includes(this.searchDescription.toLowerCase()) || JSON.stringify(item.Id).includes(this.searchDescription))
        : true;
      return matchesName;
    });
  }
  getImage(orgid) {
    var env = window.location.origin.includes('dev') ? 'spmapskmzdevva' : 'spmapskmz';
    var imageUrl = 'https://' + env + '.s3.us-east-1.amazonaws.com/Logos/Logo' + orgid + '.png'
    return imageUrl
  }
}

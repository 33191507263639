import { Component, OnInit, ViewChild } from '@angular/core';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { AuthService } from 'src/utils/auth.service';
import { MatCheckboxChange, MatDialog, MatTableDataSource } from '@angular/material';
import { MatSort, Sort } from '@angular/material/sort';
import { colorConfig } from 'src/environments/color-configs';
import { DatePipe } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-trucks',
  templateUrl: './trucks.component.html',
  styleUrls: ['./trucks.component.scss']
})
export class TrucksComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  showTable: boolean = false;
  initialSearch='';
  noRecords: boolean = false;
  trucksArray = new MatTableDataSource<any>();;
  trucksTypesArray = [];
  displayCols = ['Description', 'TruckType','RouteId' ,'SalterDeviceTypeId','uniqueid','LastUpdateUTC', 'Active','HideTruck'];
  orgId = '';
  defaultType: any;
  thBg = '#e9ba9e';
  domain = 'snowpaths';
  searchUrl = '';
  sortUrl = '';
  appVersion: number;
  orgDetails: any = {};
  description = '';
  // currentpage = 0
  // currentRow = 10;
  rowEven = 'row-orange';
  totalRecords = 0;
  length = this.totalRecords;
  pageSize = 10;
  pageEvent: PageEvent;
  defaultRows: any = this.pageSize;
  defaultPage: any = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100, 200, 500];
  assignedVisionUnits: any = [];
  activeTruckCount: any = 0;
  searchDescription: any = '';
  sensors = [];
  saltTruckOptions:any = [];
  visionTruckOptions: any = {};
  colorConfig = colorConfig;
  zoneDropdownSettings: IDropdownSettings = {};
  tokenValue: any = {};
  isMaster: boolean = false

  constructor(private apiService: ApiService, private helper: HelperService,
    private authService: AuthService, private datepipe: DatePipe, private dialog: MatDialog) { }

  ngOnInit() {
    document.addEventListener('visibilitychange', this.handleTabChange);
    this.domain = this.apiService.getLocalStorage('domain');
      this.thBg = colorConfig[this.domain]['thBackground'];
      this.rowEven = colorConfig[this.domain]['reports']['tableRowEvenClass'];
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = this.tokenValue['custom:organizationid'];
      }
    });
    this.initialSearch='&sortType=asc&sortBy=Description';
    this.getOrgDetails();
    
  }
  ngOnDestroy() {
    document.removeEventListener('visibilitychange', this.handleTabChange);
  }
  handleTabChange = () => {
    if (document.hidden) {
      console.log('Tab is inactive');
      // Modify header & footer styles here
    } else {
      // Reset header & footer styles
      this.domain = this.apiService.getLocalStorage('domain');
      this.thBg = colorConfig[this.domain]['thBackground'];
      this.rowEven = colorConfig[this.domain]['reports']['tableRowEvenClass'];
    }
  };
  async getTruckTypes() {
    var url = 'truckType?orgId=' + this.orgId+'&sortType=asc&sortBy=Name';
    this.apiService.getSearchwithPagination(url, this.searchUrl, this.sortUrl, this.defaultPage, 10000).subscribe((data: any) => {
      this.trucksTypesArray = data['truckTypes'];
      this.defaultType = data['truckTypes'][0]['Id'];
      this.totalRecords = data.count;
      if (this.totalRecords > 0) {
        this.showTable = true;
        this.noRecords = false;
      } else {
        this.noRecords = true;
      }
      // this.getTrucks();
      this.getSaltTruckOptions();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getTrucks() {
    this.zoneDropdownSettings = {
      idField: 'Id',
      textField: 'Name',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.noRecords = false;
    this.assignedVisionUnits = [];
    var url = 'truck?orgId=' + this.orgId+this.initialSearch;
    this.apiService.getSearchwithPagination(url, this.searchUrl, this.sortUrl, this.defaultPage, this.defaultRows).subscribe((data: any) => {
      this.totalRecords = data.count;
      this.assignedVisionUnits= data['assignedSaltDevices']
      this.activeTruckCount = data['activeTruckCount'];
      data['trucks'].map((truck: any) => {
        truck['RouteSelected']=[];
        if(truck['Routes'].length > 0){
          var AllZone = [{
            Name: 'All zones',
            Id: 0
          }]
          truck.Routes = AllZone.concat(truck.Routes)
          truck.Routes.forEach(element => {
            if(truck['RouteIds'].includes(element.Id)){
              truck['RouteSelected'].push(element)
            }
            return element
          });
        }
        return truck
      })
      this.trucksArray = new MatTableDataSource<any>(data['trucks']);
      this.trucksArray.sort = this.sort;
      if (this.totalRecords > 0) {
        this.noRecords = false;
        this.showTable = true;
      } else {
        this.noRecords = true;
      }
      this.initialSearch='';
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  handlePage(event: PageEvent) {
    console.log(event);
    this.defaultPage = event.pageIndex;
    this.defaultRows = event.pageSize;
    this.getTrucks();
  }
  saveData(saveObj) {

  }
  searchChange(event) {
    console.log(event)
    if (event !== '') {
      this.searchUrl = '&description=' + encodeURIComponent(event);
    } else {
      this.searchUrl = '';
    }
    this.defaultPage=0;
    this.getTrucks();
  }
  updateTruck(id, obj) {
    var url = 'truck?truckId=' + id+'&orgId='+this.orgId;
    this.initialSearch='&sortType=asc&sortBy=Description';
    this.apiService.updatedata(url, obj).subscribe((data: any) => {
      this.getTrucks();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
      this.getTrucks();
    })
  }
  activeChange(truckObj, ob: MatCheckboxChange) {
    console.log(ob.checked);
    var payload = {};
    payload['Active'] = ob.checked;
    this.updateTruck(truckObj['Id'], payload)
  }
  hideChange(truckObj, ob: MatCheckboxChange) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent , {
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to change this vehicle "'+truckObj['Description']+'" out of service? \n\n '
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if(result){
        console.log(ob.checked);
        var payload = {};
        payload['HideTruck'] = ob.checked;
        this.updateTruck(truckObj['Id'], payload)
      }else {
        truckObj.HideTruck = !ob.checked
        return truckObj
      }
    });
  }
  desChange(truckObj, value) {
    console.log(value);
    var payload = {};
    payload['TruckTypeId'] = value;
    this.updateTruck(truckObj['Id'], payload)
  }
  zoneChange(truckObj: any,key: string, selectedZone: any){
    var payload = {}
    payload['activeRoutes'] = [];
    if (selectedZone.Name === "All zones") {
      if(truckObj.RouteIds.length >0){
        var zoneStr = ''
        truckObj.Routes.forEach(element => {
          if(truckObj.RouteIds.includes(element.Id)){
            zoneStr +=element.Name+', '
          }
        });
        zoneStr = zoneStr.slice(0, zoneStr.lastIndexOf(',')) + zoneStr.slice(zoneStr.lastIndexOf(',') + 1)
        this.unAssignTruck(truckObj,[selectedZone.Id],truckObj.RouteIds,zoneStr+" zone(s) assigned already. Do you want to assign All zones?")
      } else{
        payload['activeRoutes'].push(selectedZone.Id)
        this.updateTruck(truckObj['Id'], payload)
      }
    } else {
      payload['activeRoutes'] = [selectedZone.Id]
      if(truckObj.RouteIds.includes(0)){
        this.unAssignTruck(truckObj,[selectedZone.Id],truckObj.RouteIds,"All zones assigned already. Do you want to assign "+selectedZone.Name+" zone?")
      }else{
        this.updateTruck(truckObj['Id'], payload)
      }
    }
    return truckObj
  }
  onZoneDeSelect(truckObj: any,key: string, selectedZone: any){
      var payload = {
        "inActiveRoutes":[selectedZone.Id]
      }
      this.updateTruck(truckObj['Id'], payload)
  }

  saltChange(truckObj, value) {
    console.log(value);
    var payload = {};
    payload['SalterDeviceTypeId'] = value !== "null"?Number(value):null;
    this.updateTruck(truckObj['Id'], payload)
  }
  unitChange(truckObj: any, value: string | null) {
    console.log(value);
    var payload = {};
    payload['uniqueid'] = value !== "null"?value:null;
    this.updateTruck(truckObj['Id'], payload)
  }
  sortData(sort: Sort) {
    console.log(sort);
    this.searchUrl = '';
    if (sort.direction) {
      var key = sort.active;
      if (sort.active === "GPSPingTime") { key = "pingTime" };
      if (sort.active === "TruckType") { key = "TruckTypeId" };
      this.sortUrl = "sortBy=" + key + "&sortType=" + sort.direction;
      this.getTrucks();
    } else {
      this.sortUrl = '';
      this.getTrucks();
    }
  }
  getDateFormat(getDate){
    return this.datepipe.transform(getDate, 'M/dd/yyyy')
  }
  getGpsPingtime(seconds){
    // Second(s)
    return seconds < 0?"No Recent Activity":seconds/86400 > 1?"No Recent Activity":seconds+" Second(s)"
  }
  async getOrgDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.orgDetails = data;
      this.appVersion = data.organization['Lite'];
      this.getTruckTypes();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getSaltTruckOptions() {
    var url = 'saltUnit';
    this.saltTruckOptions = [];
    this.visionTruckOptions = {};
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      this.saltTruckOptions = data.saltUnits;
      for(let unit of this.saltTruckOptions){
        this.getVisionTruckOptions(unit.id);
      }
      this.getTrucks();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getVisionTruckOptions(saltUnitId: any) {
    var url = 'saltDevices?OrgId='+this.orgId+'&saltDeviceTypeId='+saltUnitId;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.visionTruckOptions[saltUnitId] = data;   
      // this.getTrucks();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  getHeader(header){
    var column = header;
      column = header === 'LastUpdateUTC'?"Last Update UTC":header === 'TruckType'?"Vehicle Type":header === 'GPSPingTime'?"GPS Ping Time":header === 'HideTruck'?"Retire Vehicle":header === 'SalterDeviceTypeId'?'Salt Unit':header === 'uniqueid'?'Vision unit':header === 'Description'?'Vehicle Name':header === 'RouteId'?'Zone':header;
      return column
  }
  getOptions(rowData: any){
    var visionOptions = [];
    if(this.visionTruckOptions[rowData.SalterDeviceTypeId]){
      this.visionTruckOptions[rowData.SalterDeviceTypeId].map((vision: any) => {
        if(!this.assignedVisionUnits.includes(vision.uniqueid) || vision.uniqueid === rowData.uniqueid){
          visionOptions.push(vision);
        }
      })
    }
    return visionOptions
  }
  getZones(rowData: any){
    var visionOptions = [];
    if(this.visionTruckOptions[rowData.SalterDeviceTypeId]){
      this.visionTruckOptions[rowData.SalterDeviceTypeId].map((vision: any) => {
        if(!this.assignedVisionUnits.includes(vision.uniqueid) || vision.uniqueid === rowData.uniqueid){
          visionOptions.push(vision);
        }
      })
    }
    return visionOptions
  }
  async unAssignTruck(truckObj,activeRoutes,inActiveRoutes: any,message: string) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent , {
      data: {
        title: 'Confirmation',
        message: message
      }
    });
    confirmDialog.afterClosed().subscribe(async result => {
      console.log(result)
      if(result){
        var payload = {};
        payload['inActiveRoutes'] = inActiveRoutes;
        payload['activeRoutes'] = activeRoutes;
        this.updateTruck(truckObj['Id'], payload)
        this.helper.openSnackBar("Existing zones Unassigned & selected zone assigned successfully.","Ok")
      }
    });
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { AuthService } from 'src/utils/auth.service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Router } from '@angular/router';
import { colorConfig } from 'src/environments/color-configs';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  StartTime = new Date();
  StopTime = new Date();
  authResult:any;
  orgId: any = '';
  searchData={}
  showCharts=false;
  chartsData={};
  domain="snowpaths";
  panelBg = 'rgb(249 217 199)'
  bgColor = 'rgb(253, 235, 224)';
  searchBg = 'rgb(221, 92, 15)';
  labelColor = 'black';
  tokenValue: any = {};
  isMaster: boolean = false;

  constructor(private apiService: ApiService, private helper: HelperService,
    private authService: AuthService, public datepipe: DatePipe,
    private router: Router) { }

  ngOnInit() {
    document.addEventListener('visibilitychange', this.handleTabChange);
    this.domain = this.apiService.getLocalStorage('domain');
      this.panelBg = colorConfig[this.domain]['statistics']['filterBackground'];
      this.searchBg = colorConfig[this.domain]['statistics']['searchBackground'];
      this.bgColor = colorConfig[this.domain]['statistics']['background'];
      this.labelColor=colorConfig[this.domain]['reportChart']['labelColor'];
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        // if(storageRole === 'Master'){
        //   this.isMaster = true;
        //   var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
        //   var organizationId = window.location.href.split('manageOrg=').pop()
        //   var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
        //   this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
        //   this.apiService.managementToken = currentOrgToken[1];
        //   this.apiService.useManagementToken = true;
        // }else {
        // }
        this.apiService.managementToken = session.getIdToken().getJwtToken();
        this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = Number(this.tokenValue['custom:organizationid']);
        this.searchData['OrgId'] = Number(this.orgId);
        // this.getPortals();
        // this.getMapDetails();
      } else {
        this.router.navigate[('/')]
      }
    });
    if (!this.authResult) {
      // alert("Hi")
      localStorage.clear();
      this.router.navigate[('/')]
    }
    this.StopTime.setMinutes(59);
    this.StopTime.setSeconds(0);
    this.StopTime.setHours(23);
    this.StartTime.setMinutes(0);
    this.StartTime.setSeconds(0);
    this.StartTime.setDate(this.StopTime.getDate());
    this.StartTime.setHours(0)
  }
  ngOnDestroy() {
    document.removeEventListener('visibilitychange', this.handleTabChange);
  }
  handleTabChange = () => {
    if (document.hidden) {
      console.log('Tab is inactive');
      // Modify header & footer styles here
    } else {
      // Reset header & footer styles
      this.domain = this.apiService.getLocalStorage('domain');
      this.panelBg = colorConfig[this.domain]['statistics']['filterBackground'];
      this.searchBg = colorConfig[this.domain]['statistics']['searchBackground'];
      this.bgColor = colorConfig[this.domain]['statistics']['background'];
      this.labelColor=colorConfig[this.domain]['reportChart']['labelColor'];
    }
  };
  dateChange(getStartDate){
    console.log(getStartDate)
  }
  async getStatisticsData(){
    this.showCharts=false;
    var url = 'statisticsReport'
    var payload = Object.assign({},this.searchData);
    payload['StartTime'] = this.datepipe.transform(this.StartTime, 'yyyy-M-dd HH:mm:00.000000')
    payload['StopTime'] = this.datepipe.transform(this.StopTime, 'yyyy-M-dd HH:mm:00.000000')
    
    await this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        this.chartsData = res;
        this.showCharts=true;
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader();
        this.helper.openSnackBar(error, "OK")
      }
    )
  }

}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { AuthService } from 'src/utils/auth.service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Router } from '@angular/router';
import { colorConfig } from 'src/environments/color-configs';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-chart-report',
  templateUrl: './chart-report.component.html',
  styleUrls: ['./chart-report.component.scss']
})
export class ChartReportComponent implements OnInit {
  StartTime = new Date();
  StopTime = new Date();
  allPortals=[];
  disableSearchButton = true;
  routesArray=[];
  sampleData =  {
    "xAxis": [
      "Vehicles"
    ],
    "yAxisData": [
      {
        "data": [
          16.41015625
        ],
        "name": "280"
      },
      {
        "data": [
          4.3515625
        ],
        "name": "279"
      }
    ]
  };
  trucksArray = [];
  labelColor='black';
  selectedPortal:any;
  display="display"
  chartData= [
    [1528205400000, 193.07, 193.94, 192.36, 193.31],
    [1528291800000, 193.63, 194.08, 191.92, 193.98],
    [1528378200000, 194.14, 194.2, 192.34, 193.46],
    [1528464600000, 191.17, 192, 189.77, 191.7]
  ]
  authResult:any;
  orgId = '';
  username: string = '';
  searchData={}
  selectedTruck={};
  allTType = [{
    Id: 'all',
    Name: "all"
  }]
  allTruck = [{
    Id: 'all',
    Description: "all"
  }]
  chartsData={}
  showCharts=false;
  domain = 'snowpaths';
  gifName = 'LoadingTruck';
  panelBg = 'rgb(249 217 199)'
  bgColor = 'rgb(253, 235, 224)';
  infoBackground = 'rgb(249 217 199)';
  searchBg = 'rgb(221, 92, 15)';
  organizationData: any = [];
  liteVersion: any = null;
  loaded: boolean = false;
  loadMessage = "Search by dates to view charts";
  csvPayload: any = {};
  showsalterCsvDownload: boolean = false;
  completedPercentage = 0
  lastVisitedPortal: any = '';
  userData: any = {};
  calanderData: any = {};
  tokenValue: any = {};
  isMaster: boolean = false;

  constructor(private apiService: ApiService, private helper: HelperService,
    private authService: AuthService, public datepipe: DatePipe,private http: HttpClient,
    private router: Router) {
      const urlOrigin = window.location.origin;
    let isAvailable = urlOrigin.includes("streetpaths");
    let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    
    this.domain=apiService.getLocalStorage('domain');
    this.panelBg = colorConfig[this.domain]['reportChart']['filterBackground'];
    this.searchBg = colorConfig[this.domain]['reportChart']['searchBackground'];
    this.bgColor = colorConfig[this.domain]['reportChart']['background'];
    this.infoBackground = colorConfig[this.domain]['reportChart']['infoBackground'];
    this.labelColor=colorConfig[this.domain]['reportChart']['labelColor'];
     }

  ngOnInit() {
    document.addEventListener('visibilitychange', this.handleTabChange);
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = this.tokenValue['custom:organizationid'];
        this.username = this.tokenValue['cognito:username'];
        this.searchData['OrgId'] = Number(this.orgId);
        if (this.apiService.getLocalStorage('startTime')) {
          this.StartTime = new Date(JSON.parse(this.apiService.getLocalStorage('startTime')))
        }
        if (this.apiService.getLocalStorage('stopTime')) {
          this.StopTime = new Date(JSON.parse(this.apiService.getLocalStorage('startTime')))
        }
        this.getMapDetails();
      } else {
        this.router.navigate[('/')]
      }
    });
    if (!this.authResult) {
      // alert("Hi")
      localStorage.clear();
      this.router.navigate[('/')]
    }
    this.StopTime.setMinutes(59);
    this.StopTime.setSeconds(0);
    this.StopTime.setHours(23);
    this.StartTime.setMinutes(0);
    this.StartTime.setSeconds(0);
    this.StartTime.setDate(this.StopTime.getDate());
    this.StartTime.setHours(0)
  }
  ngOnDestroy() {
    document.removeEventListener('visibilitychange', this.handleTabChange);
  }
  handleTabChange = () => {
    if (document.hidden) {
      console.log('Tab is inactive');
      // Modify header & footer styles here
    } else {
      // Reset header & footer styles
      this.domain = this.apiService.getLocalStorage('domain');
      this.panelBg = colorConfig[this.domain]['reportChart']['filterBackground'];
    this.searchBg = colorConfig[this.domain]['reportChart']['searchBackground'];
    this.bgColor = colorConfig[this.domain]['reportChart']['background'];
    this.infoBackground = colorConfig[this.domain]['reportChart']['infoBackground'];
    this.labelColor=colorConfig[this.domain]['reportChart']['labelColor'];
    }
  };
  async getMapDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.organizationData = data;
      this.liteVersion = data.organization['Lite'];
      this.getUserDetails();
      
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getUserDetails() {
    var url = 'user?name='+this.username.toLowerCase()+'&page=0&size=10&orgId=' + this.orgId;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      console.log(data);
      if(data.users[0]){
        this.lastVisitedPortal = Number(data.users[0].lastVisitedPortal);
        this.userData = data.users[0];
      }
      this.getPortals();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getPortals() {
    var url = 'portal?orgId=' + this.orgId;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
          this.selectedPortal = this.lastVisitedPortal?Number(this.lastVisitedPortal):this.allPortals[0]['Id'];
          this.searchData['PortalId'] = this.lastVisitedPortal?Number(this.lastVisitedPortal):this.allPortals[0]['Id'];
        this.getRoutes();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  
  async getRoutes() {

    var url = 'route?orgId=' + this.orgId + '&portalId=' + this.selectedPortal;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.getTrucks();
      if (data['routes'].length > 0) {
        this.routesArray = this.allTType.concat(data['routes']);
      } else {
        this.routesArray = this.allTType;
      }
      if(this.routesArray.length > 0){
        this.routesArray = this.routesArray.filter(function( obj ) {
          return obj.Name !== 'Any';
        });
      }
      this.searchData['ZoneId']=this.routesArray[0]['Id']
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
    this.loaded=true;
  }
  async getTrucks() {
    var url = 'truck?orgId=' + this.orgId + '&portalId=' + this.selectedPortal+'&truckType=all'+'&includeInactiveTrucks=true';;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      // this.getStatusKey();
      if (data.length > 0) {
        this.trucksArray = this.allTruck.concat(data);
      } else {
        this.trucksArray = this.allTruck;
      }
      this.searchData['TruckId']=this.trucksArray[0]['Id']
      this.selectedTruck=this.trucksArray[0];
      this.disableSearchButton=false;
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
    // this.getStopTableData();
  }
  
  async getChartData(){
    this.completedPercentage = 0;
    this.loadMessage = "Loading..."
    this.showCharts=false;
    var url = 'reports?reportType=general'
    var payload = Object.assign({},this.searchData);
    payload['StartTime'] = this.datepipe.transform(this.StartTime, 'yyyy-M-dd HH:mm:00.000000')
    payload['StopTime'] = this.datepipe.transform(this.StopTime, 'yyyy-M-dd HH:mm:00.000000')
    if (payload['TruckId'] === 'all') { 
      payload['TruckId'] = null 
    }else {
      payload['TruckId']=Number(payload['TruckId'])
    }
    if (payload['ZoneId'] === 'all') { 
      payload['ZoneId'] = null
     }else {
      payload['ZoneId']=Number(payload['ZoneId'])
     }
     this.csvPayload = payload;
    await this.apiService.postNewReportdata(url, payload, true).subscribe(
      (res: any) => {
        console.log(res);
        this.chartsData = res;
        this.completedPercentage = 15;
        this.getpercentCoveredInRoute(payload)
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader();
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getpercentCoveredInRoute(payload){


    var url = 'reports?reportType=percentCoveredInRoute'
    await this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        this.chartsData = {...this.chartsData, ...res}
        this.completedPercentage = 30;
        this.getnoOfSectionDravelledInZone(payload)
        
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader();
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getnoOfSectionDravelledInZone(payload){
    var url = 'reports?reportType=noOfSectionDravelledInZone'
    await this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        this.chartsData = {...this.chartsData, ...res}
        this.completedPercentage = 45;
        this.getnoOfMilesDravelledInZone(payload);
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader();
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getnoOfMilesDravelledInZone(payload){
    var url = 'reports?reportType=noOfMilesDravelledInZone'
    await this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        this.chartsData = {...this.chartsData, ...res}
        this.completedPercentage = 65;
        this.getdistanceTravelled(payload);
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader();
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getdistanceTravelled(payload){
    var url = 'reports?reportType=distanceTravelled'
    await this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        this.chartsData = {...this.chartsData, ...res}
        this.completedPercentage = 80;
        this.getsaltUsedByTruck(payload);
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader();
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getsaltUsedByTruck(payload){
    this.showsalterCsvDownload = false;
    var url = 'reports?reportType=saltUsedByTruck'
    await this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        this.completedPercentage = 100;
        console.log(res);
        this.chartsData = {...this.chartsData, ...res}
        if(this.chartsData['saltUsedByTruck'] && this.chartsData['saltUsedByTruck'].yAxisData
        && this.chartsData['saltUsedByTruck'].yAxisData[0] && this.chartsData['saltUsedByTruck'].yAxisData[0].data
        && this.chartsData['saltUsedByTruck'].yAxisData[0].data[0]){
          this.showsalterCsvDownload = true;
        }
        console.log(this.chartsData)
        this.showCharts=true;
        this.loadMessage = "Search by dates to view charts";
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader();
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getportalId(portalId) {
    // this.showPage = false;
    console.log(portalId);
    this.selectedPortal = Number(portalId);
    this.searchData['PortalId'] = Number(portalId);
    this.getRoutes();
  }
  getChange(key,event){
    this.selectedTruck = event.value;
    this.searchData[key]=event.value.Id;
  }
  dateChange(getType,getStartDate){
    console.log(getStartDate)
    if(getType === 'StartTime'){
      this.StopTime = new Date(this.StartTime)
      this.StopTime.setMinutes(0);
      this.StopTime.setSeconds(0);
      // this.StartTime.setMinutes(0);
      // this.StartTime.setSeconds(0);
      this.StopTime.setDate(this.StartTime.getDate() + 1);
      this.StopTime.setHours(this.StartTime.getHours());
    }
  }
  async downloadSalterCsv(){
    var url = 'reports?reportType=downloadSaltCSV '
    await this.apiService.postNewdata(url, this.csvPayload).subscribe(
      (res: any) => {
        console.log(res);
        this.http.get(res.saltCSVS3path, { responseType: 'text' }).subscribe(
          (data) => {
            var base64Response = btoa(data);
            this.downloadFile(base64Response, 'Salt raw data/Org_'+this.csvPayload.OrgId+'/Portal_'+this.csvPayload.PortalId+'.csv', 'text/csv');
          },
          (error) => {
            console.error('Error downloading CSV:', error);
          }
        );
      }, (error) => {
        console.log(error);
        this.apiService.hideLoader();
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  private downloadFile(base64Data: any, filename: string, type: string) {
    const decodedData = atob(base64Data);
    const blob = new Blob([decodedData], { type: 'text/csv' });
    saveAs(blob, filename);
    this.helper.openSnackBar("CSV file downloaded successfully!", "OK")
  }
  onMonthChange(event){
    this.calanderData= {}
    // Calculate the number of days in the month
    const numberOfDays = new Date(event.year, event.month + 1, 0).getDate();
    var startDate = event.year+'-'+event.month+'-'+'01'
    var endDate = event.year+'-'+event.month+'-'+numberOfDays
    this.getCalanderDates(startDate,endDate)
  }
  onOpenCalendar(event) {
    const currentDate = new Date(this.StartTime);
    // Get the month (0-indexed) and year
    const month = currentDate.getMonth()+1;
    const year = currentDate.getFullYear();

    // Calculate the number of days in the month
    const numberOfDays = new Date(year, month, 0).getDate();
    var startDate = year+'-'+month+'-'+'01'
    var endDate = year+'-'+month+'-'+numberOfDays
    this.getCalanderDates(startDate,endDate)
  }
  getCalanderDates(startDate,endDate){
    var url = 'spDataForDate';
    var payload = {
      "orgId":Number(this.orgId),
      "portalId":this.selectedPortal,
      "startDate":startDate,
      "endDate":endDate
    }
    this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        this.calanderData= res
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ApiService } from './api.service';
import * as mom from 'moment-timezone';
import { ConfirmOkDialogComponent } from '../shared/components/confirm-ok-dialog/confirm-ok-dialog.component';
import { environment } from 'src/environments/environment';
import { EditComponent } from '../shared/components/edit/edit.component';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private apiService: ApiService, private dialog: MatDialog,
    private snackBar : MatSnackBar) { }

  async loginUser(username: string, password: string) {
    console.log('try authenticating the user against Cognito');

    // try {
    //         var user = await Auth.signIn(username.toString(), password.toString());
    //         console.log('Authentication performed for user=' + username + 'password=' + password + ' login result==' + user);
    //         var tokens = user.signInUserSession;
    //         if (tokens != null) {
    //           console.log('User authenticated');
    //           this.apiService.addLocalStorage('isAuthenticated', true);
    //           this.apiService.addLocalStorage('token', tokens['idToken']['jwtToken']);
    //           this.apiService.addLocalStorage('refreshToken', tokens['refreshToken']['token']);
    //           this.apiService.addLocalStorage('username', username);
    //         }
    // } catch (error) {
    //         console.log(error);
    //         alert('User Authentication failed');
    // }
  }


  async logoutUser($event) {
        console.log();
        this.apiService.removeLocalStorage('isAuthenticated');
        this.apiService.removeLocalStorage('token');
        this.apiService.removeLocalStorage('refreshToken');
        this.apiService.removeLocalStorage('username');
  }

  isAuthenticated() {
    const isAuthed = this.apiService.getLocalStorage('isAuthenticated');
    return isAuthed == 'true';
  }

  downloadImage(result, fileName) {
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([result]));
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  downloadPdfFromBlob(data,pdfName){
    const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = pdfName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
  }
  openSnackBar(message: string, action: string) {
    if(message === "Authentication failed.Invalid token"){
      message = "Session expired, please Login again"
    }
    if(message.toLowerCase().includes("jdbc")){
      message = "Connection lost retrying."
    }
    this.snackBar.open(message, action, {
    duration: 20000,
  });
  }
  openQuickSnackBar(message: string, action: string) {
    if(message === "Authentication failed.Invalid token"){
      message = "Session expired, please Login again"
    }
    if(message.toLowerCase().includes("jdbc") || message.toLowerCase().includes("too many")){
      message = "Connection lost retrying."
    }
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  changeTimeZoneWithTime(getText,timeZome){
    var text = getText;
    var textList = getText.split('Last Updated: ');
    var dateText = textList[1].split('<br />')[0]
    const uTCDatetime = mom.tz(dateText, "UTC").format();
    const to = mom.tz(uTCDatetime, timeZome).format("MM/DD/yyyy hh:mm A");
    var regExp = new RegExp(dateText, 'gi');
    var newstr = getText.replace(regExp, to);
    return newstr
  }
  changeTimeZone(getText,lastUpdatedDate,timeZome){
    // var text = getText;
    // var textList = getText.split('Last Updated: ');
    // var dateText = textList[1].split('<br />')[0]
    const uTCDatetime = mom.tz(lastUpdatedDate, "UTC").format();
    const to = mom.tz(uTCDatetime, timeZome).format("MM/DD/yyyy hh:mm A");
    var regExp = new RegExp(lastUpdatedDate, 'gi');
    var newstr = getText.replace(regExp, to);
    return newstr
  }
  async getLatestUIVersion(url) {
    // var url = 'ssm?key=UiVersion';
    this.apiService.getByPublicUrl(url).subscribe((data: any) => {
      if(data.value && (data.value !== environment.uiVersion)){
        if(this.dialog.openDialogs.length > 0){
          this.dialog.closeAll();
        }
        this.showReloadDialog();
      }
    }, (error) => {
      console.log(error);
      this.openSnackBar(error, "OK")
    })
  }
  async getbyUrl(url) {
    // var url = 'ssm?key=UiVersion';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      return data
    }, (error) => {
      console.log(error);
      this.openSnackBar(error, "OK")
      return [];
    })
  }
  showReloadDialog(){
    const confirmDialog = this.dialog.open(ConfirmOkDialogComponent, {
      data: {
        title: 'Alert',
        message: "A new version of the application is available, please click ok to load the new version."
      }
    });
      confirmDialog.afterClosed().subscribe(result => {
        location.reload()
      });
  }
  showNewAlerts(modalData){
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: 'System Updates',
        data: modalData,
        type: 'newSystemUpdate'
      },
      minWidth: 250
    });
    confirmDialog.afterClosed().subscribe(result => {
      
    });
  }
}

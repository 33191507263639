import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { colorConfig } from 'src/environments/color-configs';
import { AuthService } from 'src/utils/auth.service';

import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-jasper-report',
  templateUrl: './jasper-report.component.html',
  styleUrls: ['./jasper-report.component.scss']
})
export class JasperReportComponent implements OnInit {

  tabs = [{ title: 'Home', contentType: 'home', selectedAgain: false }];
  nextTabIndex = 1;
  formClass = 'leftView';
  selectedPortal: any;
  labelColor: string = 'black';
  allPortals = [];
  searchData: any = {}
  bgColor = 'rgb(253, 235, 224)';
  domain = 'snowpaths';
  buttonTextColor = 'black';
  urlOrigin = '';
  searchBg = 'rgb(221, 92, 15)';
  headerBg = '#f1772e';
  truckDropdownSettings
  authResult: any;
  orgId: any = '';
  username: string = '';
  lastVisitedPortal: any = '';
  userData: any = {};
  OrgDetails: {} = {}
  routesArray=[];
  allTType = [{
    Id: 'all',
    Name: "all"
  }]
  allTransportRoutes = [{
    Id: 'all',
    RouteName: "all"
  }]
  allTruck = [{
    Id: 'all',
    Description: "all"
  }]
  loaded: boolean = false;
  trucksArray = [];
  selectedTruck: any = []
  disableSearchButton: boolean = true;
  StartTime = new Date();
  StopTime = new Date();
  calanderData: any = {};
  selectedTabIndex= new FormControl(0);
  dailyReports = [];
  selectedPdf: any = '';
  selectedReportType:string = '';
  viewBy: string = 'list';
  vehicleReportTypes = [
    {
      "tabLabel":"Daily Report",
      "type": "TruckDailyReport",
      "header": "Daily Report",
      "description": "Tracks vehicle stops drive times and total movements."
    },
    {
      "tabLabel":"Disconnect Report",
      "type": "RevealTruckStoppageReport",
      "header": "Reveal Disconnect Report",
      "description": "Records all vehicle stops stop locations and total stop time."
    },
    {
      "tabLabel":"Distance Report",
      "type": "TruckDistanceTravelledReport",
      "header":  "Distance Travelled Report",
      "description": "Total distance travelled by each vehicle."
    },
    {
      "tabLabel":"Cost Report",
      "type": "CostAnalysisReport",
      "header":  "Cost Analysis Report",
      "description": "Calculates vehicle costs per mile and per idle minute."
    },
    {
      "tabLabel":"Street Sweeper Count Report",
      "type": "StreetSweeperReport",
      "header":  "Street Sweeper Count Report",
      "description": "Total vehicle's street sweeper count."
    },
    {
      "tabLabel":"Salt Report By Day",
      "type": "SaltSpreadByDayReport",
      "header":  "Salt Spread By Day Report",
      "description": "Total salt spread per lane mile."
    },
    {
      "tabLabel":"Salt Report By Zone",
      "type": "SaltSpreadByZoneReport",
      "header":  "Salt Spread By Zone Report",
      "description": "Total salt usage per lane mile within each zone."
    },
    {
      "tabLabel":"Salt Report By Road type",
      "type": "SaltSpreadByRoadTypeReport",
      "header":  "Salt Spread By Road Type Report",
      "description": "Calculates salt usage per lane mile for each road type by priority."
    },
    {
      "tabLabel":"Pin Report",
      "type": "PinsReport",
      "header":  "Pin Report",
      "description": "Details the time, location, and description of each dropped pin."
    },
    {
      "tabLabel":"Bus Stop Report",
      "type": "BusStopReport",
      "header":  "Bus Stop Report",
      "description": "Details the time, location, and description of each Bus Stop."
    },
    {
      "tabLabel":"Transport Route Report",
      "type": "TransportRouteReport",
      "header":  "Transport Route Report",
      "description": "Records the time, location, and description of each Transport Route."
    },
    {
      "tabLabel":"Bus Travel Report",
      "type": "BusTravelReport",
      "header":  "Bus Travel Report",
      "description": "Details the timing and stops of bus travel Route ."
    }
    
  ]
  commanReportTypes = [
    
  ]
  costPerMileData = [
    {
      label: 0,
      value: 0
    },
    {
      label: 0.25,
      value: 0.25
    },
    {
      label: 0.5,
      value: 0.5
    }
  ]
  idleCostPerMileData = [
    {
      label: 0,
      value: 0
    },
    {
      label: 0.25,
      value: 0.25
    },
    {
      label: 0.5,
      value: 0.5
    }
  ]
  sweeperReportTypeData = [
    {
      label: "Count",
      value: "Count"
    }
  ]
  // {
  //   label: "Street Sweeper",
  //   value: "StreetSweeper"
  // },
  costPerMile = 0.25
  idleCostPerMile = 0.25
  costPerTonForSalt = 0.25
  costPerGallonLiquid = 0.25
  sweeperReportType = this.sweeperReportTypeData[0]
  problemType: any = null;
  problemTypeList = []
  tokenValue: any = {};
  isMaster: boolean = false;
  id: any;
  showReports: boolean = false;
  isTransportHub: boolean = false;
  transportRoutesArray: any = [];

  constructor(private helper: HelperService, private apiService: ApiService,
    private authService: AuthService, private router: Router, private datepipe: DatePipe) { }

  ngOnInit() {
    document.addEventListener('visibilitychange', this.handleTabChange);
    const urlOrigin = window.location.origin;
    this.urlOrigin = window.location.origin;
    let isAvailable = urlOrigin.includes("streetpaths");
    let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    if (isFleetpathAvailable) {
      this.domain = 'fleetpaths';
    }
    if (isAvailable) {
      this.domain = 'streetpaths';
    }
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.viewBy = window.innerWidth < 640 ? 'grid':'list';
        this.authResult = session;
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = Number(this.tokenValue['custom:organizationid']);
        this.username = this.tokenValue['cognito:username'];
        this.searchData['OrgId'] = Number(this.orgId);
        if (this.apiService.getLocalStorage('startTime')) {
          this.StartTime = new Date(JSON.parse(this.apiService.getLocalStorage('startTime')))
        }
        if (this.apiService.getLocalStorage('stopTime')) {
          this.StopTime = new Date(JSON.parse(this.apiService.getLocalStorage('startTime')))
        }
        this.getUserDetails();
      } else {
        this.router.navigate[('/')]
      }
    });
    if (!this.authResult) {
      localStorage.clear();
      this.router.navigate[('/')]
    }
    this.StopTime.setMinutes(59);
    this.StopTime.setSeconds(0);
    this.StopTime.setHours(23);
    this.StartTime.setMinutes(0);
    this.StartTime.setSeconds(0);
    this.StartTime.setDate(this.StopTime.getDate());
    this.StartTime.setHours(0)
    this.domain=this.apiService.getLocalStorage('domain');
    this.handlePortalStyles();
  }
  handlePortalStyles() {
    this.buttonTextColor = this.domain === 'snowpaths' ? colorConfig[this.domain]['buttonColorBlack'] :
      (this.domain === 'streetpaths' || this.domain === 'fleetpaths' || this.domain === 'mover') ? colorConfig[this.domain]['buttonColorBlack'] : 'black'
    this.bgColor = colorConfig[this.domain]['reports']['background'];
    this.searchBg = colorConfig[this.domain]['reports']['searchBg'];
    this.headerBg = colorConfig[this.domain]['headerBackground'];
    // this.labelColor = colorConfig[this.domain]['reports']['labelColor'];
    // if(this.domain === 'mower'){
    //   this.labelColor = 'black'
    // }
  }
  getLatestReport(event){
    this.searchData.PortalId = Number(event);
    this.dailyReports = [];
    this.getdailyReportsData();
  }
  
  addTab(tle, contentT, changeTab) {
    var reportObj = this.vehicleReportTypes.concat(this.commanReportTypes).filter(report => {
      return report.type === contentT
    });
    console.log(reportObj)
    this.selectedReportType = contentT;
    var title = tle?tle:reportObj[0]['tabLabel']
    if((contentT === 'TruckDailyReport' || contentT === 'RevealTruckStoppageReport' || contentT === 'TruckDistanceTravelledReport'
      || contentT === 'SaltSpreadByDayReport' || contentT === 'CostAnalysisReport' || contentT === 'StreetSweeperReport' ||
      contentT === 'SaltSpreadByZoneReport' || contentT === 'SaltSpreadByRoadTypeReport' || contentT === 'PinsReport' || contentT === 'BusStopReport' ||
      contentT === 'TransportRouteReport' || contentT === 'BusTravelReport'
    ) && !changeTab){
      this.generateReport();
    }
    
    var selectedTab = this.tabs.filter(tab => {
      return tab.contentType === contentT
    })
    if(selectedTab.length === 0){
      this.tabs.push({ title: title, contentType: contentT, selectedAgain: false });
      if(changeTab){
        this.selectedTabIndex.setValue(this.tabs.length - 1);
      }
    }else {
      var tabIndex = this.tabs.findIndex(tab => {return tab.contentType === contentT})
      this.tabs[tabIndex].selectedAgain = true;
        this.selectedTabIndex.setValue(tabIndex);
      setTimeout(() => {
        this.tabs[tabIndex].selectedAgain = false;
      }, 4000);
    }
  }

  closeTab(index: number) {
    this.tabs.splice(index, 1);
  }
  async getPortals() {
    this.isTransportHub = false;
    var url = 'portal?orgId=' + this.orgId;
    var selectedPortalObj: any = {}
    this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
        this.selectedPortal = this.lastVisitedPortal?Number(this.lastVisitedPortal):this.allPortals[0]['Id'];
        this.searchData['PortalId'] = this.lastVisitedPortal?Number(this.lastVisitedPortal):this.allPortals[0]['Id'];
        selectedPortalObj = this.allPortals.find(obj => {
          return obj.Id === this.selectedPortal;
        })
        this.isTransportHub = selectedPortalObj && selectedPortalObj.IsTransportHub ? true : false;
        this.getRoutes();
          //   if (!this.isTransportHub) {
          //   } else {
          //     this.getTransportRoutes();
          // }
      }, (error) => {
        this.helper.openSnackBar(error, "OK")
        console.log(error);
      }
    )
  }
  async getRoutes() {

    var url = 'route?orgId=' + this.orgId + '&portalId=' + this.selectedPortal;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.getproblemTypes();
      if (data['routes'].length > 0) {
        this.routesArray = this.allTType.concat(data['routes']);
      } else {
        this.routesArray = this.allTType;
      }
      if(this.routesArray.length > 0){
        this.routesArray = this.routesArray.filter(function( obj ) {
          return obj.Name !== 'Any';
        });
      }
      this.searchData['ZoneId']=this.routesArray[0]['Id']
      this.getTransportRoutes();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
    this.loaded=true;
  }
  async getTrucks() {
    this.selectedTruck = [];
    var url = 'truck?orgId=' + this.orgId + '&portalId=' + this.selectedPortal+'&truckType=all'+'&includeInactiveTrucks=true';;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      // this.getStatusKey();
      this.truckDropdownSettings = {
        idField: 'Id',
        textField: 'Description',
        enableCheckAll: false,
        allowSearchFilter: true
      };
      if (data.length > 0) {
        this.trucksArray = this.allTruck.concat(data);
      } else {
        this.trucksArray = this.allTruck;
      }
      // this.searchData['TruckId']=this.trucksArray[0]['Id']
      this.selectedTruck = [];
      this.selectedTruck.push(this.trucksArray[0]);
      // this.searchData['TruckId'] = []
      // this.searchData['TruckId'].push(this.trucksArray[0])
      this.disableSearchButton=false;
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
    // this.getStopTableData();
  }
  async getTrucksByZoneId(event: any) {
    if(this.searchData['ZoneId'] !== 'all'){
      this.selectedTruck = [];
      this.trucksArray = [];
      var url = 'transport?orgId=' + this.orgId + '&portalId=' + this.selectedPortal+'&transportRouteId='+this.searchData['ZoneId'];
      this.apiService.getByUrl(url).subscribe((data: any) => {
        // this.getStatusKey();
        this.truckDropdownSettings = {
          idField: 'Id',
          textField: 'Description',
          enableCheckAll: false,
          allowSearchFilter: true
        };
        if (data.length > 0) {
          this.trucksArray = this.allTruck.concat(data);
        } else {
          this.trucksArray = this.allTruck;
        }
        // this.searchData['TruckId']=this.trucksArray[0]['Id']
        this.selectedTruck = [];
        this.selectedTruck.push(this.trucksArray[0]);
        // this.searchData['TruckId'] = []
        // this.searchData['TruckId'].push(this.trucksArray[0])
        this.disableSearchButton=false;
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      })
    } else {
      this.getTrucks();
    }
  }
  getChange(key,event){
    this.selectedTruck = event.value;
    this.searchData[key]=event.value.Id;
  }
  onTruckSelect(selectedTruck: any) {
    console.log(selectedTruck);
    
    if (selectedTruck.Description === "all") {
      this.selectedTruck = []
      this.selectedTruck.push(selectedTruck)
    } else {
      this.selectedTruck = this.selectedTruck.filter(truck => {
        return truck.Description !== "all"
      })
    }
  }
  async getUserDetails() {
    var url = 'user?name=' + this.username.toLowerCase() + '&page=0&size=10&orgId=' + this.orgId;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      console.log(data);
      if (data.users[0]) {
        this.lastVisitedPortal = Number(data.users[0].lastVisitedPortal);
        this.userData = data.users[0];
      }
      this.getOrgDetails();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getOrgDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.OrgDetails = data;
      this.getPortals();
      this.showReports = true;
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  dateChange(getType,getStartDate){
    console.log(getStartDate)
    if(getType === 'StartTime'){
      this.StopTime = new Date(this.StartTime)
      this.StopTime.setMinutes(0);
      this.StopTime.setSeconds(0);
      // this.StartTime.setMinutes(0);
      // this.StartTime.setSeconds(0);
      this.StopTime.setDate(this.StartTime.getDate() + 1);
      this.StopTime.setHours(this.StartTime.getHours());
    }
  }
  onMonthChange(event){
    this.calanderData= {}
    // Calculate the number of days in the month
    const numberOfDays = new Date(event.year, event.month + 1, 0).getDate();
    var startDate = event.year+'-'+event.month+'-'+'01'
    var endDate = event.year+'-'+event.month+'-'+numberOfDays
    this.getCalanderDates(startDate,endDate)
  }
  onOpenCalendar(event) {
    const currentDate = new Date(this.StartTime);
    // Get the month (0-indexed) and year
    const month = currentDate.getMonth()+1;
    const year = currentDate.getFullYear();

    // Calculate the number of days in the month
    const numberOfDays = new Date(year, month, 0).getDate();
    var startDate = year+'-'+month+'-'+'01'
    var endDate = year+'-'+month+'-'+numberOfDays
    this.getCalanderDates(startDate,endDate)
  }
  getCalanderDates(startDate,endDate){
    var url = 'spDataForDate';
    var payload = {
      "orgId":Number(this.orgId),
      "portalId":this.selectedPortal,
      "startDate":startDate,
      "endDate":endDate
    }
    this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        this.calanderData= res
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async generateReport(){
    // var type = this.selectedReportType === 'TruckDailyReport'?'TruckDailyReport':this.selectedReportType === 'TruckDistanceTravelledReport'?'TruckDistanceTravelledReport':this.selectedReportType === 'SaltSpreadByDayReport'?'SaltSpreadByDayReport':
    // this.selectedReportType === 'CostAnalysisReport'?'CostAnalysisReport':this.selectedReportType === 'StreetSweeperReport'?'StreetSweeperReport':this.selectedReportType ==='RevealTruckStoppageReport'?'RevealTruckStoppageReport':'';
    var url = 'jasperReports?reportType='+this.selectedReportType
    var payload = Object.assign({},this.searchData);
    payload['StartTime'] = this.datepipe.transform(this.StartTime, 'yyyy-M-dd HH:mm:00.000000')
    payload['StopTime'] = this.datepipe.transform(this.StopTime, 'yyyy-M-dd HH:mm:00.000000')
    if(this.selectedReportType === 'CostAnalysisReport'){
      payload['costPerMile']=this.costPerMile;
      payload['idleCostPerMile']=this.idleCostPerMile;
      payload['costPerTonForSalt'] = this.costPerTonForSalt
      payload['costPerGallonLiquid'] = this.costPerGallonLiquid
    }
    if(this.selectedReportType === 'StreetSweeperReport'){
      payload['sweeperReportType']=this.sweeperReportType.value;
    }
    if(this.selectedReportType === 'PinsReport'){
      payload['problemType']=this.problemType;
    }
    // if (payload['TruckId'] === 'all') { 
    //   payload['TruckId'] = null 
    // }else {
    //   payload['TruckId']=Number(payload['TruckId'])
    // }
    var truckIds = []
    this.selectedTruck.map(truck => {
      truckIds.push(truck.Id)
    })
    payload['TruckId'] = truckIds.includes('all') ? [0] : truckIds;
    if (payload['ZoneId'] === 'all') { 
      payload['ZoneId'] = null
     }else {
      payload['ZoneId']=Number(payload['ZoneId'])
     }
     payload['PortalId']=Number(payload['PortalId'])
    await this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        this.helper.openQuickSnackBar("Report generation initialized","Ok")
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  onTabChanged(event: any){
    this.dailyReports = [];
    console.log(this.tabs)
    this.selectedTabIndex.setValue(event);
    this.selectedReportType = this.tabs[event]['contentType'];
    if(this.selectedReportType !== 'home'){
      this.selectedPdf = '';
      this.getdailyReportsData();
      if(this.id){
        clearInterval(this.id);
      }
      this.id = setInterval(() => {
        if(this.selectedReportType && this.selectedReportType !== 'home'){
          this.getdailyReportsData();
        }
      }, 20000);
    }
  }
  ngOnDestroy() {
    document.removeEventListener('visibilitychange', this.handleTabChange);
    if (this.id) {
      clearInterval(this.id);
    }
  }
  handleTabChange = () => {
    if (document.hidden) {
      console.log('Tab is inactive');
      // Modify header & footer styles here
    } else {
      // Reset header & footer styles
      this.domain = this.apiService.getLocalStorage('domain');
      this.handlePortalStyles();
    }
  };
  async getdailyReportsData(){
    // var type = this.selectedReportType === 'TruckDailyReport'?'TruckDailyReport':this.selectedReportType === 'RevealTruckStoppageReport'?'RevealTruckStoppageReport':
    // this.selectedReportType === 'TruckDistanceTravelledReport'?'TruckDistanceTravelledReport':this.selectedReportType === 'SaltSpreadByDayReport'?'SaltSpreadByDayReport':
    // this.selectedReportType === 'CostAnalysisReport'?'CostAnalysisReport':this.selectedReportType === 'StreetSweeperReport'?'StreetSweeperReport':''
    var url = 'jasperReports?orgId='+this.orgId+'&portalId='+this.searchData.PortalId+'&reportType='+this.selectedReportType;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        if(res &&res['length'] && res['length'] > 0){
          this.dailyReports = res
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getObjbyId(type: string, getId:any){
    var name = '';
    var sourcelist = [];
    sourcelist = type === 'portal'?this.allPortals:type === 'zone'?this.routesArray:[];
    if(type === 'portal' || type === 'zone'){
      sourcelist.map((obj: any) => {
        if(obj.Id === getId){
          name = obj.Name;
        }
      })
    }
    if(type === 'truck'){
      this.trucksArray.map((obj: any, index) => {
        if(Number(getId) === obj.Id){
          name += (name === ''? obj.Description:', '+obj.Description);
        }
      })
    }
    return name
  }
  showPdf(report){
    this.selectedPdf = report.ReportS3Url;
    this.apiService.showLoader();
  }
  onLoadPdfError(event){
    this.helper.openQuickSnackBar("Something problem on report PDF. Regenerate again","Ok")
  }
  onProgress(progressData: PDFProgressData) {
    this.apiService.hideLoader();
  }
  afterPDFLoadCompleted(pdf: PDFDocumentProxy){
    this.apiService.hideLoader();
  }
  downloadPdf(){
    this.apiService.downloadPdf(this.selectedPdf).subscribe(
      (data: Blob) => {
      this.helper.downloadPdfFromBlob(data,this.selectedPdf.split("/").pop())
      },
      error => {
        console.error('Error downloading PDF:', error);
      }
    );
  }
  async getproblemTypes() {
    var url = 'ssm?key=SiteProblemTypes';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.problemTypeList = data.value.split(',');
      this.getTrucks();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  getportalId(portalId) {
    // this.showPage = false;
    console.log(portalId);
    this.selectedPortal = Number(portalId);
    this.getRoutes();
  }
  async getTransportRoutes() {
    var url = 'transport?trackRouteBus=false';
    var payload = {
      "orgId": Number(this.orgId),
      "portalId": this.selectedPortal,
      "transportRouteId": [0]
    }
    
    await this.apiService.postNewdata(url, payload).subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.transportRoutesArray = this.allTransportRoutes.concat(data);
        } else {
          this.transportRoutesArray = this.allTransportRoutes;
        }
        if(this.transportRoutesArray.length > 0){
          this.transportRoutesArray = this.transportRoutesArray.filter(function( obj ) {
            return obj.RouteName !== 'Any';
          });
        }
        this.searchData['ZoneId']=this.transportRoutesArray[0]['Id']
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
}

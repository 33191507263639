import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/api/helper.service';
import { ApiService } from 'src/app/api/api.service';
import { environment } from 'src/environments/environment';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
// import { KeycloakService } from 'keycloak-angular';
// import * as jwt_decode from "jwt-decode";
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/utils/auth.service';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { colorConfig } from 'src/environments/color-configs';
import { DOCUMENT, Location } from '@angular/common';
import { Title } from '@angular/platform-browser'; 
import { EditComponent } from '../edit/edit.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
// import {img} from '../../../../'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

  username = '';
  showStevenMenu=false;
  envStyles = '';
  role = '';
  showAdminHeaders = true;
  showUserHeaders: boolean = false;
  showUser = environment.userPage;
  domain = 'snowpaths';
  bgColor = 'rgb(241, 119, 46)';
  logo ='SnowPaths-logo';
  authResult:any;
  orgId='';
  routerUrl = '';
  appVersion:number;
  orgDetails: any;
  pilotMode: number;
  GIS: number;
  pageLoaded=false;
  isLoginPage = false;
  currentUrl = '';
  isSmartpath: boolean = false;
  isReportPage = false;
  isDevMode: boolean = false;
  notificationCount = 0;
  newReleaseAlert = [];
  showAlertIcon: boolean = false;
  activeAlertIds = [];
  tokenValue: any = {};
  isLogoutFromManagement: boolean = false;
  isMaster: boolean = false;
  // icon ='../../../../tholos.png'
  constructor(@Inject(DOCUMENT) private _document: HTMLDocument,private apiService: ApiService, private authService: AuthService,
              private dialog: MatDialog, private helper: HelperService, private router: Router,
              private titleService: Title, private bottomSheet: MatBottomSheet, private location: Location) { }

  ngOnInit() {
    this.isLogoutFromManagement = false;
    const urlOrigin = window.location.origin;
    this.currentUrl = this.location.path();
    this.routerUrl = this.router.url
    if(this.currentUrl.includes("login") || this.currentUrl.includes("forgot-password")){
      this.isLoginPage = true;
    }
    // this.isReportPage = cur
    // if(this.isLoginPage){
    var isAvailable = urlOrigin.includes("streetpaths");
    var isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    var isSnowpathsAvailable = urlOrigin.includes("snowpaths");
    if(!this.apiService.domain){
      this.domain = isAvailable?"streetpaths":isFleetpathAvailable?"fleetpaths":"snowpaths";
    }
    if(this.apiService.domain){
      this.domain = this.apiService.domain;
    }
    this.apiService.addLocalStorage('domain',this.domain);
  // }
  if(!this.isLoginPage){
    this.domain=this.apiService.getLocalStorage('domain');
    
  }
    this.handlePortalStyles();
    if(this.authService.getAuthenticatedUser()){
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master' && !window.location.href.includes('Management-console')
          && !window.location.href.includes('Dashboard') && !window.location.href.includes('Release-alerts')
        && !window.location.href.includes('Statistics') && !window.location.href.includes('load-street')
        && !window.location.href.includes('jobs')){
          if(window.location.href.includes('manageOrg=')){
            this.isMaster = true;
            var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
            var organizationId = window.location.href.split('manageOrg=').pop()
            if(masterTokens[Number(organizationId)]){
              var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
            }else {
              this.helper.openSnackBar("Select org from management console page to authenticate.","Ok")
              this.apiService.managementToken = session.getIdToken().getJwtToken();
              this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
              this.router.navigate(['Management-console'])
            }
            this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
            this.apiService.managementToken = currentOrgToken[1];
            this.apiService.useManagementToken = true;
            this.isLogoutFromManagement = true;
            if(isAvailable && this.isLoginPage){
              this._document.getElementById('appFavicon').setAttribute('href', 'streetpaths-favicon.png');
              this.titleService.setTitle('- streetpaths_MC-4.0'); 
              this.domain="streetpaths";
              this.apiService.addLocalStorage('domain','streetpaths')
            }
            if(isSnowpathsAvailable && this.isLoginPage){
              this._document.getElementById('appFavicon').setAttribute('href', 'snowpaths-favicon.png');
              this.titleService.setTitle('- snowpaths_MC-4.0'); 
              this.domain="snowpaths";
              this.apiService.addLocalStorage('domain','snowpaths')
            }
            if(isFleetpathAvailable && this.isLoginPage){
              this._document.getElementById('appFavicon').setAttribute('href', 'fleetpaths-favicon.png');
              this.titleService.setTitle('- fleetpaths_MC-4.0'); 
              this.domain="fleetpaths";
              this.apiService.addLocalStorage('domain','fleetpaths')
            }
          } else {
            this.apiService.managementToken = session.getIdToken().getJwtToken();
            this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
            this.router.navigate(['Management-console'])
          }
        }else {
          if(window.location.href.includes('Management-console') || window.location.href.includes('Dashboard')
            || window.location.href.includes('Release-alerts') || window.location.href.includes('Statistics')
          || window.location.href.includes('load-street') || window.location.href.includes('jobs')){
            if(isAvailable){
              this._document.getElementById('appFavicon').setAttribute('href', 'streetpaths-favicon.png');
              this.titleService.setTitle('- streetpaths_MC-4.0'); 
              this.domain="streetpaths";
              this.apiService.addLocalStorage('domain','streetpaths')
            }
            if(isSnowpathsAvailable){
              this._document.getElementById('appFavicon').setAttribute('href', 'snowpaths-favicon.png');
              this.titleService.setTitle('- snowpaths_MC-4.0'); 
              this.domain="snowpaths";
              this.apiService.addLocalStorage('domain','snowpaths')
            }
            if(isFleetpathAvailable){
              this._document.getElementById('appFavicon').setAttribute('href', 'fleetpaths-favicon.png');
              this.titleService.setTitle('- fleetpaths_MC-4.0'); 
              this.domain="fleetpaths";
              this.apiService.addLocalStorage('domain','fleetpaths')
            }
          }
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.apiService.useManagementToken = false;
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        // let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = this.tokenValue['custom:organizationid'];
        this.getOrgDetails();
      } else {
        if(this.routerUrl !== "/login"){
          this.router.navigate[('/')]
        }else{
          return
        }
      }
    });}
    if(!this.authResult){
      if(this.routerUrl !== "/login"){
        this.router.navigate[('/')]
      }else{
        return
      }
    }
    this.role = this.apiService.getLocalStorage('role');
    if(this.role && this.role.toLowerCase() !== 'admin' && this.role.toLowerCase() !== 'master'){this.showAdminHeaders = false}
    if(this.role && this.role.toLowerCase() === 'user'){this.showUserHeaders = true}
    
    this.getUsername();
    this.envStyles = environment.envName;
  }
  handlePortalStyles(){
    if(this.currentUrl.split('/').pop() !== 'Report'){
    this.domain=this.apiService.getLocalStorage('domain');
  } else {
    if(this.apiService.domain){
      this.domain = this.apiService.domain;
    }
  }
  this.isSmartpath=JSON.parse(this.apiService.getLocalStorage('isSmartpath'));
  if(this.domain){
    this.updateThemeColor();
    this.logo = colorConfig[this.domain]['logoName'];
    this.bgColor = colorConfig[this.domain]['headerBackground'];
  }
  }
  updateThemeColor() {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
      metaTag.setAttribute('content', colorConfig[this.domain]['headerBackground']);
    } else {
      this.createThemeColorMetaTag();
    }
  }

  createThemeColorMetaTag() {
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'theme-color');
    meta.setAttribute('content', colorConfig[this.domain]['headerBackground']);
    document.head.appendChild(meta);
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }
  
  
  async getUsername() {
    const name = window.location.href.includes('Management-console')?this.apiService.getLocalStorage('username'):this.tokenValue['cognito:username'];;
    if(name && name.includes("steven")){
      this.showStevenMenu=true;
    }
    if (name && name != this.username) {
      this.username = name;
      console.log("name".toLowerCase);
    }
    return name;
  }
  goto(getPath) {
    // this.toggleSideBarForMe.emit();
    var qParam = {}
    if( this.role === 'Master'){
      qParam = { manageOrg: this.orgId }
    }
    this.router.navigate([getPath],{ queryParams: qParam});
  }
  gotoFromMC(getPath) {
    // this.toggleSideBarForMe.emit();
    this.router.navigate([getPath]);
  }
  isAuthenticated() {
    this.domain = this.apiService.getLocalStorage('domain');
    if(this.domain){
      this.handlePortalStyles();
    }
    const res = this.helper.isAuthenticated();
    return this.helper.isAuthenticated();
    // return true;
  }
  async logoutUser($event) {
    this.authService.logout();
    localStorage.clear();
    this.router.navigate(['/']);
  }
  async onLogout() {
    var userpoolData = JSON.parse(atob(localStorage.getItem('poolData')));
    var user = localStorage.getItem('username');
    var domain = '';
    if(user){
      domain = user.slice(
        user.indexOf('@') + 1,
        user.lastIndexOf('.'),
        ).toLowerCase();
      }
      // this.POOL_DATA.UserPoolId = poolData[domain]['userPoolId'];
    // this.POOL_DATA.ClientId = poolData[domain]['clientId']
    const poolData = {
      UserPoolId: userpoolData[domain]['userPoolId'],
      ClientId: userpoolData[domain]['clientId']
    };
    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    console.log(cognitoUser);
    this.apiService.domain = undefined;
    try {
      await cognitoUser.signOut();
      this.router.navigate(['login']);
      this.apiService.addLocalStorage('reportData',null);
      this.apiService.domain = null;
      localStorage.clear();
      this.helper.openQuickSnackBar("Logout successfully!","Ok")
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }
  async onLogoutFromManagementTab() {
    var existingOrgIds = this.apiService.getLocalStorage('masterTokens');
    var orgTokensOnStorage = existingOrgIds?JSON.parse(existingOrgIds):{};
    delete orgTokensOnStorage[this.orgId]
    this.apiService.addLocalStorage('masterTokens',JSON.stringify(orgTokensOnStorage))
    window.close();
  }
  changePassword() {
    var title = 'Change Password';

    const modalData = {};
    modalData['editData'] = {};
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        message: ' ',
        data: modalData,
        orgId: this.orgId,
        type: 'changePassword'
      },
      minWidth: 60
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.reLogin();
      }
    });
  }
  async reLogin() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Password changed successfully',
        message: 'Please login again with new password for: \n\n ' + this.username
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        // this.onLogout()
        this.updateAndLogoff();
      }
    });
  }
  async getOrgDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      if(data){
        this.orgDetails = data;
        this.appVersion = data.organization['Lite']
        this.pilotMode = data.organization['Pilot'];
        this.GIS = data.organization['GIS'];
        this.apiService.setAppVersion(this.appVersion);
      }
      this.getNewReleaseAlert();
      this.pageLoaded = true;
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  updateAndLogoff(){
    var activeUserId = this.apiService.getLocalStorage('activeUserId')
    var url = 'browserDetails?id=' + activeUserId;
    this.apiService.updatedata(url,{}).subscribe((data: any) => {
      console.log(data);
      if(!this.isLogoutFromManagement){
        this.onLogout()
      }else {
        this.onLogoutFromManagementTab();
      }
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  openBottomSheet(): void {
    this.bottomSheet.open(BottomSheetComponent);
  }
  gotoInstruction(getType){
    // let data = {};
    // data['infoType']=getType
    var qParam = { 
      instructionType: JSON.stringify(getType)
    }
    if( this.role === 'Master'){
      qParam['manageOrg'] = this.orgId
    }
    this.router.navigate(['/Admin/Instructions'],{ queryParams: qParam});
  }
  gotoGridZone(type: string,getUrl: any){
    if(this.pilotMode !==1){
      var qParam = { 
        reportScreenType: type
      }
      if( this.role === 'Master'){
        qParam['manageOrg'] = this.orgId
      }
      this.router.navigate([getUrl],{ queryParams: qParam});

    }
  }
  getNewReleaseAlert(){
    this.newReleaseAlert = [];
    var url = "alerts?type=NewRelease";
    this.apiService.getByUrl(url).subscribe((res: any) => {
      if(res.NewRelease && res.NewRelease.length && res.NewRelease.length >0){
      this.newReleaseAlert = res.NewRelease;
      var activeAlertFromStorage = this.apiService.getLocalStorage('activeAlertsId');
      var activeAlertFromStorageList = (activeAlertFromStorage !== "" && activeAlertFromStorage !== null)? activeAlertFromStorage.split(","):[]
      this.activeAlertIds = activeAlertFromStorageList;
      var filteredArray = this.filterArrayByAnother(this.newReleaseAlert, activeAlertFromStorageList);
      this.notificationCount = filteredArray.length;
    }
    }, (error) => {
      console.log("No new Release alerts.")
    })
  }
  filterArrayByAnother(mainArray, filterArray) {
    return mainArray.filter(obj1 =>
      filterArray.some(obj2 => obj1.Id === Number(obj2))
    );
  }
  showNewAlerts(){
    this.notificationCount = 0;
  }
  checkIdExists(id){
    var num = `${id}`;
    var state = this.activeAlertIds.includes(`${id}`)?true:false
    return state
  }
  getPath(url){
    window.open(url, '_blank');
    // this.router.navigate([path]);
  }
  checkRoleAndManagementToken(){
    // var state = (this.role === 'Master' && 
    //   (window.location.href.includes('Management-console') || (window.location.href.includes('Dashboard') && !window.location.href.includes('manageOrg'))))?false:true;
      
      var state = (this.role === 'Master' && 
        ((window.location.href.includes('Management-console') || 
        window.location.href.includes('Release-alerts') || 
        window.location.href.includes('Statistics') || 
        window.location.href.includes('Dashboard') || 
        window.location.href.includes('load-street') || 
        window.location.href.includes('jobs')) && 
        !window.location.href.includes('manageOrg')))?false:window.location.href.includes('manageOrg')?true:true;
        return state
      }
  checkMCHeaders(){
    var state = (this.role === 'Master' && 
      (window.location.href.includes('Management-console') || 
      window.location.href.includes('Release-alerts') || 
      window.location.href.includes('Statistics') || 
      window.location.href.includes('Dashboard') || 
      window.location.href.includes('load-street') || 
      window.location.href.includes('jobs')) &&
      !window.location.href.includes('manageOrg'))?true:false;
    return state
  }
  goToExternalPageWithUrl(url){
    window.open(url, '_blank');
  }
}
